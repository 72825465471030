<template>
  <div class="q-pr-lg text-h6 page-title text-right print-hide">
    <q-icon name="mdi-clipboard-list-outline" color="primary" size="md" class="q-mb-xs q-pr-md"></q-icon>
    <span>Work Traveler</span>
  </div>

  <div class="q-pt-xl q-px-lg" style="max-width: 900px; margin: 0 auto">
    <div class="q-mb-lg">Enter the Quote Number and click the <i class="lato">Build Traveler</i> button to start</div>
    <div class="row q-col-gutter-xs">
      <div class="col-12 row">
        <!--1312/1313-->
        <q-input-base
          v-model="QuoteNumber"
          label="Quote Number"
          @clear="ClearInput()"
          clearable
          style="white-space: nowrap; width: 130px"
        ></q-input-base>
        <q-button-base hidden="true" @click="BuildTraveler()" label="Build Traveler" class="q-ml-sm"></q-button-base>
        <q-button-base v-if="ShowDownloadBtn" @click="DownloadPDF()" label="Download Traveler" class="q-ml-sm"></q-button-base>
      </div>
    </div>

    <q-separator class="q-my-xs" />

    <div id="traveler2" v-html="html2" class="q-mb-sm q-mt-lg" style="mfargin: 0 auto; width: 100%; font-size: 0.75em"></div>
    <div
      v-show="!HideTable"
      id="traveler"
      v-html="html"
      class="q-mb-sm"
      style="margin: 0 auto; width: 100%; font-size: 0.85em"
    ></div>
    <div class="q-my-md"></div>
  </div>
</template>

<script>
import axios from "axios";

import QInputBase from "@/controls/QInputBase.vue";
import QButtonBase from "@/controls/QButtonBase.vue";

import { HideLoading, MsgBox, ShowLoading, FormatDate, FormatDate2, Now } from "../library";

import Fraction from "fraction.js";
import html2pdf from "html2pdf.js";

export default {
  name: "Traveler",

  components: {
    QInputBase,
    QButtonBase
  },

  setup() {},

  data() {
    return {
      HideTable: true,

      QuoteNumber: "1604", // 1313 new

      TravelerHeader: "",
      TravelerItems: "",

      API_PATH: process.env.VUE_APP_API_PATH,
      headers: { "Content-Type": "application/json" },

      Fraction,

      pageCount: 0,
      totalPages: 0,
      html: "",
      html2: "",
      pdf: "",
      ShowDownloadBtn: false
    };
  },

  methods: {
    ClearInput() {
      this.html2 = "";
      this.ShowDownloadBtn = false;
    },

    GetReport: async function () {
      try {
        ShowLoading();

        const args = { QuoteNumber: this.QuoteNumber, ReportName: "DEALERQUOTE" };
        var { data } = await axios.post(`${this.API_PATH}/api/cisapi/GetQuoteReport`, args, this.headers);

        this.pdf = `data:application/pdf;base64,${data.data.GetQuoteReportResponse.GetQuoteReportResult.Report}`;

        this.HideTable = true;
      } catch (err) {
        MsgBox(err.message);
      } finally {
        HideLoading();
      }
    },

    DownloadPDF: async function () {
      try {
        ShowLoading("Preparing file for download");

        this.HideTable = false;

        var element = document.createElement("div"); //document.getElementById("traveler");
        element.style.fontSize = "0.85em";
        element.innerHTML = this.html;

        // top left bottom right
        var pdfOptions = {
          margin: [0.3, 0.25, 0.13, 0.25],
          filename: `${this.QuoteNumber}-traveler.pdf`,
          image: { type: "jpeg", quality: 0.95 },
          html2canvas: { scale: 3 },
          jsPDF: { unit: "in", format: "letter", orientation: "landscape" }
        };

        await html2pdf().from(element).set(pdfOptions).toPdf().save();

        try {
          browser.downloads.showDefaultFolder();
        } catch (err) {}
      } catch (err) {
        MsgBox(err.message);
      } finally {
        HideLoading();

        this.HideTable = true;
        this.ShowDownloadBtn = false;

        MsgBox(
          `The file has been saved in your <i>Downloads</i> folder.<br /><br />Work Traveler: ${this.QuoteNumber}-Traveler.pdf`,
          undefined,
          undefined,
          undefined,
          10000
        );
      }
    },

    BuildTraveler: async function () {
      try {
        if (this.QuoteNumber.toString().length === 0) {
          MsgBox("Type the Quote Number");

          return;
        }

        ShowLoading("Generating Work Traveler");

        this.ShowDownloadBtn = false;

        var args = { QuoteNumber: this.QuoteNumber };
        let { data } = await axios.post(`${this.API_PATH}/api/cisapi/GetQuoteProductList`, args, this.headers);

        if (data.isError) {
          throw new Error(data.message);
        }

        this.TravelerHeader = data.data[0].Header;
        this.TravelerItems = data.data[1].Items;

        this.totalPages = Math.ceil(this.TravelerItems.length / 32);

        // filter 13-99, SEJ (10-) and the rest that is an ITEM
        let arr = [];
        this.TravelerItems.forEach((element) => {
          if (element.items.Name === "13-99") {
            arr.push(element);
          }
        });
        this.TravelerItems.forEach((element) => {
          if (element.items.Name.startsWith("10-")) {
            arr.push(element);
          }
        });
        this.TravelerItems.forEach((element) => {
          if (element.items.Name !== "13-99" && !element.items.Name.startsWith("10-") && element.items.AggregateType === "Item") {
            element.items.Name = `${element.items.Name}*`;
            arr.push(element);
          }
        });
        arr = arr.sort((a, b) => a.items.Name.localeCompare(b.items.Name));
        this.TravelerItems = arr;

        await this.BuildTable();

        if (this.TravelerHeader.OrderNumber === "") {
          MsgBox("Warning: This Quote hasn't been ordered yet");
        }

        this.HideTable = true; //false
        this.ShowDownloadBtn = true;
      } catch (err) {
        HideLoading();

        MsgBox(err.message);
      } finally {
        HideLoading();
      }
    },

    BuildTable: async function () {
      try {
        var header;
        var item;

        var ItemIndex = 0;
        var ItemIndexNoClear = 0;
        var AddHeader = true;
        var FirstPageBreak = false;

        this.html = "";
        this.html2 = "";

        this.pageCount = 0;

        var date = new Date();
        var _date =
          ("00" + (date.getMonth() + 1)).slice(-2) +
          "/" +
          ("00" + date.getDate()).slice(-2) +
          "/" +
          date.getFullYear() +
          " " +
          ("00" + date.getHours()).slice(-2) +
          ":" +
          ("00" + date.getMinutes()).slice(-2);

        const x = ["13-99", "10-", "*"];

        x.forEach((element1) => {
          this.TravelerItems.forEach((Item) => {
            if (Item.items.Name.includes(element1)) {
              if (AddHeader) {
                this.pageCount++;

                header = this.GetNewHeader(FirstPageBreak);
                FirstPageBreak = true;

                this.html += header.replace("{{replace}}", ``);
                if (this.html2.length === 0) this.html2 += header.replace("{{replace}}", "");

                AddHeader = false;
              }

              // some cut_length have more than 1 item.
              // Calculate the ubound to add to ItemIndex
              // to have a correct calculation of the page break
              var ArrayQty = Item.items.cut_length.toString().split(",");

              if (ArrayQty.length === 1) {
                ItemIndex++;
                ItemIndexNoClear++;
              } else {
                ItemIndex += ArrayQty.length;
                ItemIndexNoClear++;
              }

              if (ItemIndex <= 32) {
                var fromNewItem = this.GetNewItem(Item, ItemIndex);

                item = fromNewItem.HTML;
                this.html += item;
                this.html2 += item;

                if (fromNewItem.AddExtraLineCounter) {
                  ItemIndex++;
                  ItemIndexNoClear++;
                }
              } else {
                this.html += `<div class='q-mt-xs row text-grey-8 small-font'><div class='col-6'>Printed on ${_date} | Control file WT-${this.QuoteNumber}</div>`;
                this.html += `<div class='q-mt-xs col-6 text-right'>Page ${this.pageCount}/${this.totalPages}</div>`;
                this.html += `</div>`;

                header = this.GetNewHeader(FirstPageBreak);
                FirstPageBreak = true;

                this.html += header.replace("{{replace}}", ``);
                if (this.html2.length === 0) this.html2 += header.replace("{{replace}}", "");

                var fromNewItem = this.GetNewItem(Item, ItemIndex);

                item = fromNewItem.HTML;
                this.html += item;
                this.html2 += item;

                if (fromNewItem.AddExtraLineCounter) {
                  ItemIndex++;
                  ItemIndexNoClear++;
                }

                this.pageCount++;
                AddHeader = false;
                ItemIndex = 0;
              }
            } else {
              //this.html += "<div style='bb page-break-after: always'></div>";
            }
          });

          if (ItemIndexNoClear !== this.TravelerItems.length) {
            this.html += "<div class='bb2'></div>";
            this.html2 += "<div class='bb2'></div>";
          }
        });

        this.html += `<div class='q-mt-xs row text-grey-8 small-font'><div class='col-6'>Printed on ${_date} | Control file WT-${this.QuoteNumber}</div>`;
        this.html += `<div class='q-mt-xs col-6 text-right'>Page ${this.pageCount}/${this.totalPages}</div>`;
        this.html += `</div>`;
        //this.html += `<br />`;
      } catch (err) {
        MsgBox(err.message);
      }
    },

    GetNewHeader: function (FirstPageBreak) {
      return `${
        !FirstPageBreak
          ? '<table style="width: 100%" cellpadding="0" cellspacing="0">'
          : '<table style="width: 100%; page-break-before: always" cellpadding="0" cellspacing="0">'
      }
        <tr>
          <td>
            <table cellpadding="0" cellspacing="0">
              <tr>
                <td class="text-weight-medium text-right">MODEL:&nbsp;&nbsp;</td>
                <td>${this.TravelerHeader.ModelName}</td>
              </tr>
              <tr>
                <td class="text-weight-medium text-right">SMART PART:&nbsp;&nbsp;</td>
                <td>${this.TravelerHeader.SmartPart.toUpperCase()} (QTY ${this.TravelerHeader.Quantity})</td>
              </tr>
              <tr>
                <td></td>
                <td class='${this.TravelerHeader.producttype.length > 40 ? "smaller-font" : ""}'>
                  ${
                    this.TravelerHeader.producttype.length > 40
                      ? this.TravelerHeader.producttype.substring(0, 40).toUpperCase() + "..."
                      : this.TravelerHeader.producttype.toUpperCase()
                  }
                </td>
              </tr>
              <tr>
                <td class="text-weight-medium text-right">SALES ORDER:&nbsp;&nbsp;</td>
                <td>${this.TravelerHeader.OrderNumber}</td>
              </tr>
            </table>
          </td>
          <!-- 2 -->
          <td>
            <table cellpadding="0" cellspacing="0">
              <tr>
                <td class="text-weight-medium text-right">CUSTOMER NAME:&nbsp;&nbsp;</td>
                <td>${this.TravelerHeader.CustomerName.toUpperCase()} ${this.TravelerHeader.CustomerNumber}</td>
              </tr>
              <tr>
                <td class="text-weight-medium text-right">ADDRESS:&nbsp;&nbsp;</td>
                <td>${this.TravelerHeader.ShipAddress1.toUpperCase()}</td>
              </tr>
              <tr>
                <td></td>
                <td>${this.TravelerHeader.ShipCity.toUpperCase()} ${this.TravelerHeader.ShipState.toUpperCase()}, ${
        this.TravelerHeader.ShipZipCode
      }</td>
              </tr>
              <tr>
                <td class="text-weight-medium text-right">ORDER DATE:&nbsp;&nbsp;</td>
                <td>${Now()}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td></td>
              </tr>
            </table>
          </td>
          <!-- 3 -->
          <td>
            <table cellpadding="0" cellspacing="0">
              <tr>
                <td class="text-weight-medium text-right">ENTERED BY:&nbsp;&nbsp;</td>
                <td>${this.TravelerHeader.QuoteCreator.toUpperCase()}</td>
              </tr>
              <tr>
                <td class="text-weight-medium text-right">KEYED BY:&nbsp;&nbsp;</td>
                <td>${this.TravelerHeader.keyedby.toUpperCase()}</td>
              </tr>
              <tr>
                <td class="text-weight-medium text-right">SHIP VIA:&nbsp;&nbsp;</td>
                <td>${this.TravelerHeader.shippingcode}</td>
              </tr>
              <tr>
                <td class="text-weight-medium text-right">SHIP DATE:&nbsp;&nbsp;</td>
                <td>${FormatDate2(this.TravelerHeader.shipdate)}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td></td>
              </tr>
            </table>
          </td>
          <!-- 4 Image -->
          <td style="vertical-align: top">
            <table cellpadding="0" cellspacing="0">
              <tr>
                <td class="text-weight-medium">WORK TRAVELER</td>
              </tr>
              <tr>
                <td style="padding-left: 30px; padding-top: 5px">
                  <img
                    alt="Southeastern Aluminum Logo"
                    src="logo.png"
                    style="width: 40px; height: 40px; position: absolute"
                  />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <!-- pages -->
      <div class="text-right q-mr-lg">{{replace}}</div>
      <table style="width: 100%" cellpadding="0" cellspacing="0">
        <tr>
          <td style="width: 100%">
            <table style="width: 100%; background-color: rgb(235, 235, 235)" class="text-weight-medium">
              <tr>
                <td style="width: 10%">ITEM #</td>
                <td style="width: 25%">DESCRIPTION</td>
                <td style="width: 5%" class="text-center">QTY</td>
                <td style="width: 10%" class="text-center">CUT LENGTH</td>
                <td style="width: 7%" class="text-center">WIDTH</td>
                <td style="width: 7%" class="text-center">HEIGHT</td>
                <td style="width: 8%" class="text-center">THICKNESS</td>
                <td style="width: 9%" class="text-center">BT LEVEL <span style="font-size: 8pt">+/- </span></td>
                <td style="width: 9%" class="text-center">OUTAGE</td>
                <td style="width: 10%" class="text-center">GLASS TYPE</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>`;
    },

    GetNewItem: function (Item, ItemIndex) {
      //console.log('item', Item);
      var AddExtraLineCounter = false;

      var _html = `<div>
        <span class="row ${Item.items.comment === "" ? "bb" : ""}">
          <div style="width: 10%">${Item.items.Name.replace("*", "")}</div>
          <div style="width: 25%">${Item.items.Description}</div>
          <div style="width: 5%" class="text-center">${Item.items.Quantity > 0 ? Item.items.Quantity : ""}</div>
          <div style="width: 10%">${this.ConvertToFractionCutLength(Item.items.cut_length)}</div>
          <div style="width: 7%" class="text-center">${this.ConvertToFraction(Item.items.width)}</div>
          <div style="width: 7%" class="text-center">${this.ConvertToFraction(Item.items.height)}</div>
          <div style="width: 8%" class="text-center">${this.ConvertToFraction(Item.items.thickness)}</div>
          <div style="width: 9%" class="text-center">${
            Item.items.bt_level > 0 ? this.ConvertToFraction(Item.items.bt_level) : ""
          }</div>
          <div style="width: 9%" class="text-center">${
            Item.items.outtage > 0 ? this.ConvertToFraction(Item.items.outtage) : ""
          }</div>
          <div style="width: 10%" class="text-center">${Item.items.glasstype}</div>
        </span>
      </div>`;

      if (Item.items.comment !== "") {
        AddExtraLineCounter = true;
        _html += `<div>
        <span class="row bb">
          <div style="width: 10%"></div>
          <div style="width: 25%" class='text-primary lato smaller-font'>${Item.items.comment.toUpperCase()}</div>
          <div style="width: 5%"></div>
          <div style="width: 10%"></div>
          <div style="width: 7%"></div>
          <div style="width: 7%"></div>
          <div style="width: 8%"></div>
          <div style="width: 9%"></div>
          <div style="width: 9%"></div>
          <div style="width: 10%"></div>
        </span>
      </div>`;
      }

      return { HTML: _html, AddExtraLineCounter: AddExtraLineCounter };
    },

    ConvertToFractionCutLength: function (StringToConvert) {
      if (StringToConvert === 0 || StringToConvert === "" || StringToConvert === undefined || StringToConvert === "undefined") {
        return "";
      } else {
        var arr = StringToConvert.toString().split(" , ");

        var html = "";
        var arr2;

        for (var i = 0; i <= arr.length; i++) {
          if (arr[i] !== undefined) {
            arr2 = arr[i].split(" x ");
            html += `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${arr2[0]} x ${this.ConvertToFraction(arr2[1])}<br />`;
          }
        }

        if (html.endsWith("x <br />")) {
          return "";
        } else {
          return html;
        }
      }
    },

    ConvertToFraction: function (NumberToConvert) {
      if (NumberToConvert === 0 || NumberToConvert === "" || NumberToConvert === undefined || NumberToConvert === "undefined") {
        return "";
      }

      var arr = NumberToConvert.toString().split(".");

      if (arr[1] !== undefined) {
        var f = new Fraction(Number(`0.${arr[1]}`));
        var arr2 = f.toFraction().split("/");
        var frac = `<sup style='font-size: 6pt'>${arr2[0]}</sup>/<sub style='font-size: 6pt'>${arr2[1]}</sub>`;

        if (Number(arr[0]) === 0) {
          return `${frac}`;
        } else {
          return `${arr[0]} ${frac}`;
        }
      } else {
        if (NumberToConvert === 0) {
          return "";
        } else {
          return NumberToConvert;
        }
      }
    }
  }
};
</script>
