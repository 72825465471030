<template>
  <div class="text-h6 page-title" style="width: 100%">
    <!-- <label class="lato q-ml-xl">Account Overview</label> -->
    <!-- <label class="lato q-mr-md float-right text-grey-5">Welcome to the SEA In-House Tools</label> -->
  </div>

  <div v-if="!HidePage" style="width: 800px; margin: 0 auto">
    <div class="q-pa-xl">
      <div class="row">
        <div class="col-2 small-font">
          <table style="width: 100%">
            <tr>
              <td style="width: 100%" v-html="`${Width}.${Width2.toString().replace('0.', '')}`"></td>
            </tr>
            <tr>
              <td style="width: 100%" v-html="`${Width}''&nbsp; ${ConvertToFraction(Width2)}`"></td>
            </tr>
          </table>
        </div>
        <div class="col-10">
          <q-slider
            v-model="Width"
            :min="24"
            :max="80"
            :step="1"
            :label-value="`Width: ${Width}''`"
            :marker-labels="objMarkerLabel"
            label
            markers
            marker-labels-class="smaller-font"
          >
          </q-slider>
        </div>

        <div class="col-2" x-v-html="`${Width} ${ConvertToFraction(Width2)}`"></div>
        <div class="col-10">
          <q-slider
            v-model="Width2"
            :min="0"
            :max="0.9375"
            :step="1 / 16"
            :label-value="`Fraction: ${Width2}`"
            label
            markers
            :marker-labels="objMarkerLabel2"
            marker-labels-class="smaller-font"
          >
            <template v-slot:marker-label-group="scope">
              <div
                v-for="marker in scope.markerList"
                :key="marker.value"
                :class="[marker.classes]"
                :style="marker.style"
                @click="model = marker.value"
              >
                <span v-html="marker.label"></span>
              </div>
            </template>
          </q-slider>
        </div>
      </div>

      <q-markup-table class="q-mt-xl" separator="horizontal" dense flat bordered>
        <thead class="bg-grey-3 text-body1">
          <tr>
            <th class="text-left">Dessert (100g serving)</th>
            <th class="text-right">Calories</th>
            <th class="text-right">Fat (g)</th>
            <th class="text-right">Carbs (g)</th>
            <th class="text-right">Protein (g)</th>
            <th class="text-right">Sodium (mg)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">Frozen Yogurt</td>
            <td class="text-right">159</td>
            <td class="text-right">6</td>
            <td class="text-right">24</td>
            <td class="text-right">4</td>
            <td class="text-right">87</td>
          </tr>
          <tr>
            <td class="text-left">Ice cream sandwich</td>
            <td class="text-right">237</td>
            <td class="text-right">9</td>
            <td class="text-right">37</td>
            <td class="text-right">4.3</td>
            <td class="text-right">129</td>
          </tr>
          <tr>
            <td class="text-left">Eclair</td>
            <td class="text-right">262</td>
            <td class="text-right">16</td>
            <td class="text-right">23</td>
            <td class="text-right">6</td>
            <td class="text-right">337</td>
          </tr>
          <tr>
            <td class="text-left">Cupcake</td>
            <td class="text-right">305</td>
            <td class="text-right">3.7</td>
            <td class="text-right">67</td>
            <td class="text-right">4.3</td>
            <td class="text-right">413</td>
          </tr>
          <tr>
            <td class="text-left">Gingerbread</td>
            <td class="text-right">356</td>
            <td class="text-right">16</td>
            <td class="text-right">49</td>
            <td class="text-right">3.9</td>
            <td class="text-right">327</td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
  </div>
  <q-btn @click="GetXml()">Get XML</q-btn>
  <!-- <q-page-sticky position="top-right" :offset="[27, 100]" class="sticky lato">Welcome to the SEA In-House Tools</q-page-sticky> -->
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue';

import axios from 'axios';
import store from '@/store';

import { MsgBox, HideLoading, ShowLoading } from '@/library';

import Fraction from 'fraction.js';

name: 'Main';

const API_PATH = process.env.VUE_APP_API_PATH;
const headers = { 'Content-Type': 'application/json' };

const HidePage = ref(true);

const Width = ref(24);
const Width2 = ref(0);
const Height = ref(0);

const Frac = ref('24');

const objMarkerLabel = ref({ 24: '24"', 30: '30"', 40: '40"', 50: '50"', 60: '60"', 70: '70"', 80: '80"' });
const objMarkerLabel2 = ref({
  0: '',
  0.0625: '<sup>1</sup><span class="small-font">/</span><sub>16</sub>',
  0.125: '<sup>1</sup><span class="small-font">/</span><sub>8</sub>',
  0.1875: '<sup>3</sup><span class="small-font">/</span><sub>16</sub>',
  0.25: '<sup>1</sup><span class="small-font">/</span><sub>4</sub>',
  0.3125: '<sup>5</sup><span class="small-font">/</span><sub>16</sub>',
  0.375: '<sup>3</sup><span class="small-font">/</span><sub>8</sub>',
  0.4375: '<sup>7</sup><span class="small-font">/</span><sub>16</sub>',
  0.5: '<sup>1</sup><span class="small-font">/</span><sub>2</sub>',
  0.5625: '<sup>9</sup><span class="small-font">/</span><sub>16</sub>',
  0.625: '<sup>5</sup><span class="small-font">/</span><sub>8</sub>',
  0.6875: '<sup>11</sup><span class="small-font">/</span><sub>16</sub>',
  0.75: '<sup>3</sup><span class="small-font">/</span><sub>4</sub>',
  0.8125: '<sup>13</sup><span class="small-font">/</span><sub>16</sub>',
  0.875: '<sup>7</sup><span class="small-font">/</span><sub>8</sub>',
  0.9375: '<sup>15</sup><span class="small-font">/</span><sub>16</sub>'
});

onBeforeMount(async () => {
  ShowLoading('Please wait...');

  // shows the page once loaded
  HidePage.value = false;

  HideLoading();
});

function ConvertToFraction(NumberToConvert) {
  if (NumberToConvert === 0 || NumberToConvert === '' || NumberToConvert === undefined || NumberToConvert === 'undefined') {
    return '';
  }

  var arr = NumberToConvert.toString().split('.');

  if (arr[1] !== undefined) {
    var f = new Fraction(Number(`0.${arr[1]}`));
    var arr2 = f.toFraction().split('/');
    var frac = `<sup>${arr2[0]}</sup><span class='small-font'>/</span><sub>${arr2[1]}</sub>`;

    if (Number(arr[0]) === 0) {
      return `${frac}`;
    } else {
      return `${arr[0]} ${frac}`;
    }
  } else {
    if (NumberToConvert === 0) {
      return '';
    } else {
      return NumberToConvert;
    }
  }
}

async function GetXml() {
  const args = { CID: '11079-20220323-160627-187' };

  var { data } = await axios.get(`${this.API_PATH}/api/xml/GetXMLNewMethod`, args, this.headers);

  console.log('data', data);
}
</script>
