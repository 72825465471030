<template>
  <div class="text-h6 page-title" style="width: 100%">
    <!-- <label class="lato q-ml-xl">Account Overview</label> -->
    <!-- <label class="lato q-mr-md float-right text-grey-5">Welcome to the SEA In-House Tools</label> -->
  </div>

  <!-- <q-page-sticky position="top-right" :offset="[27, 100]" class="sticky lato">Welcome to the SEA In-House Tools</q-page-sticky> -->
</template>

<script setup>
import { ref, onBeforeMount } from "vue";

import axios from "axios";
import store from "@/store";

import { MsgBox, HideLoading, ShowLoading } from "@/library";

import Fraction from "fraction.js";

name: "Main";

const API_PATH = process.env.VUE_APP_API_PATH;
const headers = { "Content-Type": "application/json" };

const HidePage = ref(true);

const Width = ref(24);
const Height = ref(0);

const Frac = ref("24");

const objMarkerLabel = ref({ 24: '24"', 30: '30"', 40: '40"', 50: '50"', 60: '60"', 70: '70"', 80: '80"' });

onBeforeMount(async () => {
  ShowLoading("Preparing Dashboard");

  // shows the page once loaded
  HidePage.value = false;

  HideLoading();
});

function ConvertToFraction(NumberToConvert) {
  if (NumberToConvert === 0 || NumberToConvert === "" || NumberToConvert === undefined || NumberToConvert === "undefined") {
    Frac.value = "";
    return;
  }

  var arr = NumberToConvert.toString().split(".");

  if (arr[1] !== undefined) {
    var f = new Fraction(Number(`0.${arr[1]}`));
    var arr2 = f.toFraction().split("/");
    var frac = `<sup style='font-size: 6pt'>${arr2[0]}</sup><span style='font-size: 6pt'>/</span><sub style='font-size: 6pt'>${arr2[1]}</sub>`;

    if (Number(arr[0]) === 0) {
      Frac.value = `${frac}`;
    } else {
      Frac.value = `${arr[0]} ${frac}`;
    }
  } else {
    if (NumberToConvert === 0) {
      Frac.value = ``;
    } else {
      Frac.value = NumberToConvert;
    }
  }
}
</script>
